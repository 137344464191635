import { generateRandomLineNumbers } from './common';

export const generateRandomNumbers = (
  ballRanges: string[],
  usingBonusBall?: boolean
) => {
  const lineNumbers = generateRandomLineNumbers(
    ballRanges.map((r) => parseInt(r)),
    usingBonusBall
  );

  const mainNumbers = lineNumbers
    .slice(0, lineNumbers.length - (usingBonusBall ? 1 : 0))
    .map((r) => parseInt(r))
    .sort((a, b) => a - b);

  console.log('generateLineRandomNumbers', mainNumbers);

  return {
    main: mainNumbers,
    bonus: usingBonusBall
      ? parseInt(lineNumbers[lineNumbers.length - 1])
      : null,
  };
};
