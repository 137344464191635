import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';
import { runningInProduction } from 'utils/constants';

export type RoktHydration = {
  roktLauncher: Object | null;
};

export class RoktStore {
  root: RootStore;
  roktLauncher: Object | null = null

  constructor(root: RootStore) {
    this.root = root;
    makeObservable(this, {
      roktLauncher: observable,
      setRoktLauncher: action,
    });
  }

  setRoktLauncher = (launcher: Object) => {
    if (typeof window === undefined) return;
    this.roktLauncher = launcher
  }

  hydrate(data?: RoktHydration) {
    if (data?.roktLauncher) {
      this.roktLauncher = data.roktLauncher;
    }
  }
}
