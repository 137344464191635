import { ConvertScript } from './Convert';
import OrganisationSchema from './OrganisationSchema';
import RoktIntegrationLauncherScript from './RoktIntegrationLauncherScript';
import TripHuntersRedirectScript from './TripHuntersRedirectScript';
import WebsiteSchema from './WebsiteSchema';
import { analyticsEnabled, identifyUser } from 'utils/analytics/analytics';
import AffiliateFutureTag from './AffiliateFutureTag';
import FacebookPixel from './FacebookPixel';
import GoogleAdsScript from './GoogleAdsScript';
import GoogleTagManagerScript from './GoogleTagManagerScript';
import HotJarScript from './HotJarScript';
import PinterestPixel from './PinterestPixel';
import PodscribePixel from './PodscribePixel';
import SendinblueScript from './SendinblueScript';
import SnapChatPixel from './SnapChatPixel';
import TaboolaPixel from './TaboolaPixel';
import TwitterPixel from './TwitterPixel';
import { observer } from 'mobx-react-lite';
import { useCookieConsentStore } from 'hooks/stores/useCookieConsentStore';
import { useUserStore } from 'hooks/stores/useUserStore';
import { useEffect } from 'react';

const ScriptLayer = observer(() => {
  const { marketingCookiesConsent, analyticsCookiesConsent } =
    useCookieConsentStore();
  const { currentUser } = useUserStore();

  useEffect(() => {
    identifyUser({
      emailAddress: currentUser?.email_address,
      id: currentUser?.id as any,
      firstName: currentUser?.first_name,
      lastName: currentUser?.last_name,
    });
  }, [marketingCookiesConsent, analyticsCookiesConsent, currentUser]);



  return (
    <>
      <TripHuntersRedirectScript />
      <OrganisationSchema />
      <WebsiteSchema />
      <RoktIntegrationLauncherScript />
      <ConvertScript />

      {analyticsEnabled() && marketingCookiesConsent && (
        <>
          <FacebookPixel />
          <GoogleAdsScript />
          <AffiliateFutureTag />
          <SnapChatPixel />
          <SendinblueScript
            optionalEmailAddress={currentUser?.email_address || ''}
          />
          <PinterestPixel />
          <TaboolaPixel />
          <TwitterPixel />
          <PodscribePixel />
        </>
      )}

      {analyticsEnabled() && analyticsCookiesConsent && (
        <>
          <GoogleTagManagerScript />
          <HotJarScript />
        </>
      )}
    </>
  );
});

export default ScriptLayer;
