import { useEffect, useState } from 'react';

export const MOBILE_WIDTH = 768;

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    // Ensure window is defined to prevent server-side errors
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= MOBILE_WIDTH);
      };

      // Set the initial state
      handleResize();

      // Add event listener for window resizing
      window.addEventListener('resize', handleResize);

      // Cleanup the event listener
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }

    // Empty cleanup function for non-browser environments
    return () => {};
  }, []);

  return { isMobile };
};
