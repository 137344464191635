import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { ASSET_PREFIX, contentMaxWidth, colors } from 'utils/constants';
import Toast from 'components/Toast';
import { getCookie, removeCookie } from 'utils/cookie';
import {
  eligibleForReferralEntry,
  getInvitedByDetails,
  validGiftCode,
} from 'api/bonus';
import ClaimFreeEntryCard, { PaymentType } from 'components/ClaimFreeEntryCard';
import Router, { useRouter } from 'next/router';

import { ReferralBonusScheme } from 'utils/ReferralBonusScheme';
import Footer from './Footer';
import Header from './header/Header';
import PaddedMaxWidthContainer from 'components/layout/PaddedMaxWidthContainer';
import UpgradePopup from 'components/UpgradePopup/UpgradePopup';
import { extendClassName } from 'utils/common';
import { getVariantForSplitTest, SplitTest } from 'utils/SplitTesting';
import ActivityFeedBubble from 'components/activity-feed/ActivityFeedBubble';
import ActivityFeedModal from 'components/activity-feed/ActivityFeedModal';
import { useUserStore } from 'hooks/stores/useUserStore';
import { useActivityFeedStore } from 'hooks/stores/useActivityFeedStore';
import { useUIStore } from 'hooks/stores/useUIStore';
import { observer } from 'mobx-react-lite';
import { CookieConsentPopup, CookieConsentVariant } from 'components/layout/cookie-consent/CookieConsentPopup';
import { useScrollStore } from 'hooks/stores/useScrollStore';
import { doABTest } from 'utils/analytics/abtest';
import { COOKIE_CONSENT_SETTINGS_KEY } from 'utils/api/CookieConsent';

interface IProps {
  additionalClassName?: string;
  paddedMaxWidthContainer?: string;
  drawCounterInHeader?: boolean;
  children: React.ReactNode;
}

const MainLayout = observer(
  ({
    additionalClassName = '',
    paddedMaxWidthContainer = '',
    drawCounterInHeader = false,
    children,
  }: IProps) => {
    const router = useRouter();

    const { toast, displayToast, displayHeaderBanner } = useUIStore();
    const { activityFeedItems, setActivityFeedItems } = useActivityFeedStore();
    const { currentUser } = useUserStore();

    const headerHidden = ['/play-free'].includes(router.pathname);
    const footerHidden = ['/play-free', '/enter'].includes(router.pathname);
    const [showClaimFreeEntryCard, setShowClaimFreeEntryCard] = useState(false);

    const tokenTownPage = router.pathname.startsWith('/token-town');
        
    const showPremiumUpgradePrompt =
      !router.pathname.startsWith('/admin') &&
      !router.pathname.startsWith('/confirmation') &&
      !tokenTownPage &&
      !router.pathname.startsWith('/account/subscriptions/upgrade') &&
      !router.pathname.startsWith('/enter');


    const [CookieConsentStyle] = useState<CookieConsentVariant>(
      doABTest('CookieConsentStyle', ['legacy', 'zeal']) as CookieConsentVariant
    );
        
    // Fetch OngoingFunnelTracking split test variant (if any). Only one test group, used for continuously monitoring funnel conversion rate.
    const fetchOngoingFunnelTrackingVariant = async () => {
      await getVariantForSplitTest(
        currentUser,
        SplitTest.OngoingFunnelTracking,
        router.asPath
      );
    };

    const fetchOngoingWixVariant = async () => {
      const { SPLIT_TEST_NAME: wxt, VARIANT_NAME: wxv } = router.query;
      if (wxt && wxv) {
        const wixData = { wxt, wxv };
        await getVariantForSplitTest(
          currentUser,
          SplitTest.OngoingWix,
          router.asPath,
          wixData
        );
        localStorage.setItem('WX_DATA', JSON.stringify(wixData));
      }
    };

    const ableToShowActivityFeed =
      Array.isArray(activityFeedItems) &&
      activityFeedItems.length > 0 &&
      !router.pathname.startsWith('/admin');
    const [showActivityFeed, setShowActivityFeed] = useState(false);

    useEffect(() => {
      const displayReferralToastIfAppropriate = async () => {
        const referralId = getCookie('referral_id');
        if (!referralId) return;

        const jwt = getCookie('jwt', null);

        if (jwt) {
          const response = await eligibleForReferralEntry(jwt, referralId);
          if (response.successful === false) {
            removeCookie('referral_id');
            displayHeaderBanner(null);
            displayToast({
              title: response.reason ?? 'Oops, something went wrong',
              color: '#FF6600',
              timeout: 10000,
            });
            return;
          }
          localStorage.setItem('promoCode', referralId);
        }

        const invitedByDetails = await getInvitedByDetails(referralId);
        if (!invitedByDetails || invitedByDetails.successful === false) {
          displayToast({
            title: invitedByDetails.reason ?? 'Oops, something went wrong',
            color: '#FF6600',
            timeout: 10000,
          });
          return;
        }

        const { firstName, referralBonusScheme } = invitedByDetails;

        let prizeDescription = '';

        switch (referralBonusScheme) {
          case ReferralBonusScheme.ReferrerToken:
            break;

          case ReferralBonusScheme.Give6Get6:
          case ReferralBonusScheme.Give12Get12:
            prizeDescription = `Enter now to get your first 4 weeks of DAYMADE Premium for free!`;
            break;

          case ReferralBonusScheme.LocaliteAndDiscount:
          case ReferralBonusScheme.DiscountEach30pc:
            prizeDescription = `Enter now for 30% off your first order!`;
            break;

          default:
            throw new Error(
              `Don't know what to do for referral bonus scheme: ${referralBonusScheme}`
            );
        }
        setTimeout(() => {
          displayHeaderBanner({
            faIconName: 'user',
            content: `You have been referred by ${firstName}. ${prizeDescription}`,
            tapHandler: null,
            tappable: false,
          });
        }, 900);
      };

      const displayRedeemGiftBannerIfAppropriate = async () => {
        const giftCode = getCookie('gift_code');
        if (!giftCode || giftCode === '') return;

        const validCode = await validGiftCode(giftCode);
        if (!validCode) return;

        const homeWerkWinnerCode = giftCode.startsWith('HWW');

        setTimeout(() => {
          displayHeaderBanner({
            faIconName: homeWerkWinnerCode ? 'trophy' : 'ticket',
            content: (
              <>
                {homeWerkWinnerCode && (
                  <>
                    Congrats on your HomeWerk victory! 🏆🎉
                    <br />
                  </>
                )}
                Tap here to redeem your free entry!
              </>
            ),
            tapHandler: () => {
              setShowClaimFreeEntryCard(true);
            },
            tappable: true,
          });
        }, 900);
      };

      const displayPromoCodeBannerIfAppropriate = async () => {
        const promoCode = getCookie('promo_code');
        const ALLOWED_CODES = ['OURHOMEANDAWAY'];

        if (ALLOWED_CODES.includes(promoCode)) {
          displayHeaderBanner({
            faIconName: 'user',
            content: `Use code ${promoCode} at checkout to get your first 2 weeks of DAYMADE Premium for free!`,
            tapHandler: null,
            tappable: false,
          });
        }
      };

      setTimeout(() => {
        displayReferralToastIfAppropriate();
        displayRedeemGiftBannerIfAppropriate();
        displayPromoCodeBannerIfAppropriate();

        fetchOngoingFunnelTrackingVariant();
        fetchOngoingWixVariant();
      }, 100);
    }, []);

    return (
      <>
        <div className="contentContainer" style={{ display: 'none' }}>
          <div className={extendClassName('mainLayout', additionalClassName)}>
            <Head>
              {/* PWA */}
              <link rel="manifest" href="/pwa/manifest.json" />
              <meta name="apple-mobile-web-app-capable" content="yes" />

              {/* Icons */}
              <link
                rel="icon"
                type="image/x-icon"
                href={`${ASSET_PREFIX}/favicon.ico?v=2`}
              />
              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={`${ASSET_PREFIX}/apple-touch-icon.png?v=3`}
              />
            </Head>
            {/** @ts-ignore */}
            {!headerHidden && <Header showDrawCounter={drawCounterInHeader} />}

            {showPremiumUpgradePrompt && <UpgradePopup />}

            {showClaimFreeEntryCard && (
              <ClaimFreeEntryCard
                paymentType={PaymentType.GIFT}
                onClose={() => setShowClaimFreeEntryCard(false)}
                onSuccess={() => {
                  setShowClaimFreeEntryCard(false);
                  Router.push('/account');
                  displayToast({
                    title:
                      'Congrats! You have redeemed your free entry! Check your email for more details',
                    timeout: 7000,
                  });
                }}
              />
            )}

            {paddedMaxWidthContainer ? (
              <PaddedMaxWidthContainer>{children}</PaddedMaxWidthContainer>
            ) : (
              children
            )}
          </div>

          {toast && <Toast toast={toast} />}

          {!footerHidden && <Footer />}

          <CookieConsentPopup
            CookieConsentStyle={CookieConsentStyle}
          />

          <style jsx global>
            {`
              html,
              body,
              #__next {
                height: 100%;
                line-height: 1.5;
              }

              html body {
                display: flex;
                flex-direction: column;
                font-family: 'Montserrat', sans-serif;
                line-height: 1;
                color: #4a4a4a;
                margin: 0px;
                font-size: 12px;
              }

              /*
              NOTE: rather than using !important to override semantic-ui-react styles,
              we increase the priority of our styles by making them more specific.
              E.g. 'h1' → 'body h1'.
            */

              h1,
              h2,
              h3,
              h4,
              h5,
              h6,
              .spacedFont {
                font-family: 'Montserrat', sans-serif !important;
                text-align: center;
                font-weight: bold;
                letter-spacing: 0.3em;
                text-transform: uppercase;
              }

              h1 {
                font-size: 2em;
              }

              body h2.style1 {
                letter-spacing: 0;
                color: white;
                font-size: 3.5em;
                line-height: 1em;
                text-shadow: 0px 2px 1px #00000050;
              }

              body h2.style1 em {
                font-size: 0.6em;
                text-transform: none;
              }

              body h2.style1 strong::before {
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: 2.5px;
                -webkit-text-stroke-color: white;
                content: attr(data-before);
                position: absolute;
                bottom: 2px;
              }

              body h2.style1 strong {
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: 2.5px;
                -webkit-text-stroke-color: #00000050;
                text-shadow: none;
                position: relative;
              }

              body a {
                color: #4a4a4a;
                white-space: nowrap;
                text-decoration: none;
                text-align: center;
                font-weight: bold;
              }

              body a:hover {
                color: black;
              }

              body p {
                font-weight: 600;
              }

              body p strong {
                font-weight: 800;
              }

              body a,
              button,
              .roundedButton,
              h1,
              img,
              svg,
              .noSelect,
              .bundleCard,
              .line,
              .basket,
              .numberButton,
              .toggleButton,
              .purchaseSubscriptionContainer,
              .statusBadge,
              .randomButton,
              .prizeValueDropdownSelectorContainer * {
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
              }

              body .bold {
                font-weight: 700;
              }

              body .xbold {
                font-weight: 800;
              }

              body .italic {
                font-style: italic;
              }

              body .underline {
                text-decoration: underline;
              }

              body .strikethrough {
                text-decoration: line-through;
              }

              body .nowrap {
                white-space: nowrap;
              }

              body .noMargin {
                margin: 0;
              }

              body .uppercase {
                text-transform: uppercase;
              }

              body .light-grey {
                color: ${colors.primaryBrandGray}99;
              }

              body p.xlarge {
                font-size: 1.2rem;
              }

              body p.large {
                font-size: 1.05rem;
              }

              body p.medium {
                font-size: 0.9rem;
              }

              body p.small span.small {
                font-size: 0.85rem;
              }

              body p.xsmall span.xsmall {
                font-size: 0.8rem;
              }

              @media (min-width: 700px) {
                body p.xlarge {
                  font-size: 1.45rem;
                }

                body p.large {
                  font-size: 1.25rem;
                }

                body p.medium {
                  font-size: 1.05rem;
                }

                body p.small span.small {
                  font-size: 0.95rem;
                }

                body p.xsmall span.xsmall {
                  font-size: 0.85rem;
                }
              }

              .vertical-margin-1em {
                margin-top: 1em;
                margin-bottom: 1em;
              }

              .vertical-margin-2em {
                margin-top: 2em;
                margin-bottom: 2em;
              }

              .vertical-margin-3em {
                margin-top: 3em;
                margin-bottom: 3em;
              }

              .text-centered {
                text-align: center;
              }

              .text-left-aligned {
                text-align: left;
              }

              .text-left-aligned {
                text-align: left;
              }

              :global(.ui.modal .ui.primary.button) {
                background-color: ${colors.primaryBrandGray};
              }

              :global(.ui.modal .ui.primary.button:hover) {
                background-color: ${colors.primaryBrandGrayPressed};
              }

              .mainLayout {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1 0 auto;
              }

              .contentContainer {
                display: flex !important;
                flex-direction: column;
                height: 100%;
              }

              .loadingContainer {
                display: none !important;
              }

              .paddedMaxWidthContainer {
                max-width: ${contentMaxWidth};
                width: 90%;
                display: flex;
                flex-direction: column;
                align-items: center;
              }

              .no-wrap {
                white-space: nowrap;
              }

              .break-spaces {
                white-space: break-spaces;
              }

              .grid {
                display: grid;
              }

              .grid.gap-1em {
                grid-gap: 1em;
              }

              .grid.gap-2em {
                grid-gap: 2em;
              }

              .grid.columns-2 {
                grid-template-columns: 1fr 1fr;
              }

              .vertical-padding-1em {
                padding-top: 1em;
                padding-bottom: 1em;
              }

              .vertical-padding-2em {
                padding-top: 2em;
                padding-bottom: 2em;
              }

              .horizontal-padding-1em {
                padding-left: 1em;
                padding-right: 1em;
              }

              .horizontal-padding-2em {
                padding-left: 2em;
                padding-right: 2em;
              }

              .horizontal-padding-1em {
                padding-left: 1em;
                padding-right: 1em;
              }

              .horizontal-padding-2em {
                padding-left: 2em;
                padding-right: 2em;
              }

              .grid.columns-2 {
                grid-template-columns: 1fr 1fr;
              }

              .vertical-padding-1em {
                padding-top: 1em;
                padding-bottom: 1em;
              }

              .vertical-padding-2em {
                padding-top: 2em;
                padding-bottom: 2em;
              }

              .flexCentered {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }

              .flexLeftAligned {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
              }

              .flexRightAligned {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
                text-align: right;
              }

              .flexRow {
                display: flex;
                flex-direction: row;
                align-items: center;
              }

              .flexSpaceBetween {
                display: flex;
                justify-content: space-between;
              }

              .fullWidth {
                width: 100%;
              }

              .clickable {
                cursor: pointer;
              }

              .sectionContainer {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: url('${ASSET_PREFIX}/images/landing/waves.svg');
              }

              .genericButton {
                cursor: pointer;
                padding: 7px 15px;
                text-align: center;
                color: white;
                background-color: #4a4a4a;
                transition: 0.4s;
              }

              .genericButton:hover {
                background-color: #e1e1e1;
                text-decoration: none;
                transition: 0.4s;
              }

              .genericButton.light {
                background-color: white;
                color: #4a4a4a;
              }

              .genericButton.light:hover {
                background-color: #4a4a4a;
                color: white;
              }

              button {
                font-family: 'Montserrat', sans-serif;
                border: none;
                background-color: ${colors.primaryBrandGray};
                color: white;
                cursor: pointer;
                padding: 1em 2em;
                transition: 0.2s;
              }

              button:hover {
                background-color: ${colors.primaryBrandGrayPressed};
                color: ${colors.primaryBrandGray};
                transition: 0.2s;
              }

              button.secondary {
                color: #282929;
                background-color: #e0e1e2;
              }

              button.secondary:hover {
                background-color: #cacbcd;
              }

              button.destructive {
                color: #white;
                background-color: #ff6960;
              }

              button.destructive:hover {
                background-color: #f64339;
              }

              .highlightedBackgroundText {
                background-color: #39fd86;
                display: inline-block;
                padding: 4px 8px;
              }

              .blueOutlineText {
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: 1.5px;
                -webkit-text-stroke-color: #59ffde;
                text-shadow: 0 0 5px #00a1ff;
              }

              .orangeOutlineText {
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: 1.5px;
                -webkit-text-stroke-color: #ffa500;
                text-shadow: 0 0 5px #ff3500;
              }

              .pinkOutlineText {
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: 1.9px;
                -webkit-text-stroke-color: #ffa0ff;
                text-shadow: 0 0 15px rgba(216, 29, 90, 0.7);
              }

              .greenOutlineText {
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: 1.5px;
                -webkit-text-stroke-color: ${colors.trees.neon};
                text-shadow: 0 0 10px ${`${colors.trees.neon}70`},
                  0 0 5px ${colors.trees.shadow};
              }

              .greyOutlineText {
                -webkit-text-fill-color: #ffffff99;
                -webkit-text-stroke-width: 4px;
                -webkit-text-stroke-color: ${colors.primaryBrandGray};
              }

              .whiteOutlineText {
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: 3px;
                -webkit-text-stroke-color: #4a4a4a;
                -webkit-text-stroke-color: white;
                font-weight: 800;
              }

              .neonInput {
                font-family: 'Montserrat', sans-serif !important;
              }

              .arcadeFont {
                font-family: 'Press Start 2P', 'Montserrat', sans-serif;
              }

              p.arcadeFont {
                font-size: 0.9em;
              }

              p.arcadeFont strong {
                font-size: 1em;
                text-transform: uppercase;
              }

              :global(#form, #errorMessage) {
                display: flex;
                flex-direction: column;
                max-width: 500px;
                width: 100%;
              }

              :global(#form) {
                padding: 1em 0;
              }

              :global(#errorMessage) {
                padding: 1em;
              }

              :global(#form input) {
                font-size: 16px;
                border: 3px solid #e3e3e3;
              }

              .separator {
                background-color: #ddd;
                width: 80px;
                height: 2px;
                margin: 26px;
                flex-shrink: 0;
              }

              .verticalSeparator {
                background-color: #ddd;
                width: 2px;
                align-self: stretch;
              }

              :global(.bigFirstLetter) {
                font-weight: bold;
                font-size: 45px;
                text-shadow: 4px 4px ${colors.brand.neon};
              }

              :global(.scrollButton) {
                position: absolute;
                background-color: transparent;
                transition: 0.2s;
              }

              :global(.scrollButton.scalable:hover) {
                transform: scale(1.1);
              }

              @media (hover: none) and (pointer: coarse) {
                // Hide the scroll buttons if using a touch screen.
                :global(.scrollButton) {
                  display: none;
                }
              }

              .tokenTown h1,
              .tokenTown h2,
              .tokenTown h3,
              .tokenTown h4,
              .tokenTown h5 {
                letter-spacing: 0;
                text-transform: none;
              }

              .tokenTown h1,
              .tokenTown h2,
              .tokenTown input {
                font-family: 'Press Start 2P', 'Montserrat', sans-serif !important;
                color: ${colors.primaryBrandGray};
                line-height: 1.5;
                text-transform: uppercase;
              }

              .tokenTown h2 {
                font-size: 1.75em;
              }

              .tokenTown h3 {
                font-size: 1.3em;
              }

              .tokenTown h4 {
                font-size: 1.2em;
              }

              .tokenTown h5 {
                font-size: 1.1em;
              }

              .tokenTown p strong {
                font-weight: 800;
              }

              body .mobile-only {
                display: flex;
              }

              body .desktop-only {
                display: none;
              }

              @media (min-width: 700px) {
                body .mobile-only {
                  display: none;
                }

                body .desktop-only {
                  display: flex;
                }
              }

              @media (min-width: 1000px) {
                .tokenTown h3 {
                  font-size: 1.5em;
                }

                .tokenTown h4 {
                  font-size: 1.4em;
                }

                .tokenTown h5 {
                  font-size: 1.3em;
                }

                .tokenTown p,
                .tokenTown ul {
                  font-size: 1.25em;
                }
              }

              .tokenTown a:not(.desktopHeaderLink):not(.menuLink) {
                margin: 0;
                white-space: break-spaces;
                text-decoration: underline;
              }

              .tokenTown input {
                font-size: 0.7em;
              }

              .tokenTown .roundedButton {
                border-radius: 2px !important;
                background-color: #50e3c2 !important;
                box-shadow: 1px 1px #9b9b9b;
                margin-top: 1em;
                font-family: 'Press Start 2P', 'Montserrat', sans-serif !important;
                font-size: 0.9em;
              }

              .tokenTown .roundedButton.disabled {
                background-color: lightgray !important;
                pointer-events: none;
                border: none !important;
              }

              .tokenTown .roundedButton.secondary {
                border: 3px solid #50e3c2;
                background-color: white !important;
                color: #50e3c2;
                box-shadow: none;
              }

              .tokenTown ul {
                font-weight: 600;
              }

              :global(.blogPost a:not(.backButton)) {
                text-decoration: underline;
                white-space: break-spaces;
              }
            `}
          </style>

          <script
            dangerouslySetInnerHTML={{
              __html: `
          function isItIE() {
            user_agent = navigator.userAgent;
            var is_it_ie = user_agent.indexOf("MSIE ") > -1 || user_agent.indexOf("Trident/") > -1;
            return is_it_ie; 
          }
          
          if (isItIE()) {
            alert("DAYMADE unfortunately does not work well with Internet Explorer. Please try using a modern browser (such as Chrome, Firefox, Brave, Safari)."); 
          }
        `,
            }}
          />
        </div>
      </>
    );
  }
);

export default MainLayout;
