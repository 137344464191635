import { useRoktStore } from 'hooks/stores/useRoktStore';
import { observer } from 'mobx-react-lite';
import Script from 'next/script';

import { runningInProduction } from 'utils/constants';

const RoktIntegrationLauncherScript = observer(() => {
  const { roktLauncher, setRoktLauncher } =
    useRoktStore();
  const DISABLED_IN_PRODUCTION = false;

  if ((runningInProduction && DISABLED_IN_PRODUCTION)) {
    return null;
  }

  return (
    <Script
      async
      crossOrigin="anonymous"
      id="rokt-launcher"
      src="https://apps.rokt.com/wsdk/integrations/launcher.js"
      type="module"
      onLoad={async () => {
        // We need to guard against a user refreshing the application on a page where the launcher is used.
        // This scenario could cause a race condition in which the launcher instance is used by the page
        // before the instance is actually created.
        // By storing an async function and running it later in places where the instance of the
        // Integration Launcher is meant to be used, we can guarantee it will be ready each time.
        // https://docs.rokt.com/docs/developers/integration-guides/web/advanced/single-page-applications/
        if (roktLauncher !== null) {
          return roktLauncher;
        }

        // @ts-ignore
        const launcher = await window.Rokt.createLauncher({
          accountId: '3072809930891470497',
          sandbox: !runningInProduction,
        });
        setRoktLauncher(launcher);
      }}
    />
  );
});

export default RoktIntegrationLauncherScript;
